import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, EMPTY, catchError, throwError } from "rxjs";
import { AuthService } from "./modules/auth";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    storageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
    constructor(private authService: AuthService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token: any = localStorage.getItem(this.storageToken);
        token = token ? JSON.parse(token) : null;

        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token.access_token}`,
                }
            });
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    localStorage.removeItem(this.storageToken);
                    this.router.navigate(['/auth/login']).then(() => {
                        // document.location.reload();
                    });
                }
                return throwError(() => new Error(error.message || 'Server Error'));
            })
        );

    }
}