import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment'
import {handleError} from '../errorHandler/errorHandler'
import {  Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class ReportsService {
    private apiUrl : any = environment.apiUrl + "/auth"
    private url : any = environment.apiUrl

    constructor(private http : HttpClient) { }
    postClient(data:any):Observable<any>{
      return this.http.post(`${this.apiUrl}/clients`,data)
    }
    getSaveFilterData():Observable<any>{
        return this.http.get(`${this.apiUrl}/saveFilter_list`)
      }
    
      getSaveFilter(id):Observable<any>{
      return this.http.post(`${this.apiUrl}/getFiltersData`,id)
      } 

      deleteSaveFilter(id):Observable<any>{
        return this.http.post(`${this.apiUrl}/deleteFilter`,id)
      } 
      
      saveFilterData(payload):Observable<any>{
        return this.http.post(`${this.apiUrl}/saveFilter`,payload)
      }
      applyFilterData(payload):Observable<any>{
        return this.http.post(`${this.apiUrl}/creativeReport`,payload)
      }
      shareReports(payload):Observable<any>{
        return this.http.post(`${this.apiUrl}/sharedReport`,payload)
      }
      shareReportsById(reportId):Observable<any>{
        return this.http.get(`${this.url}/getSharedReport/${reportId}`)
      }
}
