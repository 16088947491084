<div style="display: flex; justify-content: center; padding: 20px;background: #f7f7f7;"
  >
    <div style="width: 100%;">
        <div class="row" *ngIf="ishideElement">
            <div class="col-lg-6">
                <div class="form-group">
                    <label style=" font-size: 15px;
                     font-weight: 600;">Location Filter
                        <span class="required">&nbsp; </span>
                    </label>
                    <select class="form-select form-control-inline" data-required="1" 
                     (change)="filterReports($event)">
                        <option value="">Select</option>
                        <option  *ngFor="let location of filterLocationData" [value]="location.id"
                         >{{location.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6 text-end"> 
                <div class="d-flex justify-content-end gap-3">       
                    <button class="btn btn-danger" (click)="printComponent('component1')"><i class="fa fa-download"></i> <span>Print report</span></button>
                    <button id="share_buttom" class="btn btn-danger" onclick="setAndCopyToClipboard('reportUrl')"
                     (click)="reportClipboard()"><i class="fa fa-share"></i> <span>Share report</span></button>
                </div>
                <p *ngIf="isVisibleElement">The report link has been copied to your clipboard. Any one with the link can view this report.<br>
                   <!-- {{currentUrl}} -->
                </p>
               </div>
        </div>
        <div id="component1">
        <div style="padding: 20px; border-radius: 0px; margin: top 15px;">
            <div style="text-align: left; font-size: 24px; font-weight: bold; margin-bottom: 15px;">
                CAMPAIGN
            </div>

           
            <div style="display: flex; gap: 15px; justify-content: space-between;">
                <div style="flex: 1; display: flex; flex-direction: column; align-items: center;">
                    <span style="font-size: 24px; color: #ffc107;">
                        <img style="width: 50px;
                        height: 50px;
                        background: #ddd;
                        border-radius: 50%;
                        padding: 15px;" src="../../../assets//media/report_icon/creativeIcn.png" alt="Random Image" class="card-img">
                    </span>
                    <span style="font-size: 25px;
                    font-weight: bold;
                    padding: 15px 0 0px;
                    color: #555555;">{{sharedData?.reportListing?.total?.total_creative_data}}</span>
                    <span style="font-weight: bold;
                    font-size: 15px;
                    color: #555;">TOTAL CREATIVE</span>
                </div>

                <div style="flex: 1; display: flex; flex-direction: column; align-items: center;">
                    <span style="font-size: 24px; color: #ffc107;">
                        <img style="width: 50px;
                        height: 50px;
                        background: #ddd;
                        border-radius: 50%;
                        padding: 15px;" src="../../../assets//media/report_icon/impression.png" alt="Random Image" class="card-img">
                    </span>
                    <span style="font-size: 25px;
                    font-weight: bold;
                    padding: 15px 0 0px;
                    color: #555555;">{{sharedData?.reportListing?.total?.total_impression}}</span>
                    <span style="font-weight: bold;
                    font-size: 15px;
                    color: #555;">TOTAL IMPRESSIONS</span>
                </div>

                <div style="flex: 1; display: flex; flex-direction: column; align-items: center;">
                    <span style="font-size: 24px; color: #ff5722;">
                        <img style="width: 50px;
                        height: 50px;
                        background: #ddd;
                        border-radius: 50%;
                        padding: 15px;" src="../../../assets//media/report_icon/playingTime.png" alt="Random Image" class="card-img">
                    </span>
                    <span style="font-size: 25px;
                    font-weight: bold;
                    padding: 15px 0 0px;
                    color: #555555;">{{sharedData?.reportListing?.total?.total_playtime}}</span>
                    <span style="font-weight: bold;
                    font-size: 15px;
                    color: #555;">TOTAL PLAYING TIME</span>
                </div>

                <div style="flex: 1; display: flex; flex-direction: column; align-items: center;">
                    <span style="font-size: 24px; color: #ff5722;">
                        <img style="width: 50px;
                        height: 50px;
                        background: #ec4019;
                        border-radius: 50%;
                        padding: 15px;" src="../../../assets//media/report_icon/deliveryIcn.png" alt="Random Image" class="card-img">
                    </span>
                    <span style="font-size: 25px;
                    font-weight: bold;
                    padding: 15px 0 0px;
                    color: #555555;">{{sharedData?.reportListing?.total?.total_delivery}}%</span>
                    <span style="font-weight: bold;
                    font-size: 15px;
                    color: #555;">DELIVERY</span>
                </div>
            </div>
            
        </div>
        <!-- <div style="display: flex; gap: 15px; justify-content: space-between; margin-top: 60px; align-items: center;"
        *ngFor="let item of sharedData?.reportListing.data">
            <div style="flex: 1; display: flex; flex-direction: column; align-items: center;">
                <span style="font-size: 24px;">
                    <img style="
                    padding: 15px;" [src]="item.file_path" alt="Random Image" class="card-img">
                </span>
                <span style="font-weight: bold;
                font-size: 15px;
                color: #555;">{{ item.title }}</span>
            </div>

            <div style="flex: 1; display: flex; align-items: center; gap: 25px;">
                <span style="font-size: 24px; color: #ffc107;">
                    <img src="../../../assets//media/report_icon/impression.png"  class="img-fluid" style="width: 40px;height: 30px;" alt="view">
                </span>
                <div style="text-align: center;">
                    <span style="font-size: 25px;
                font-weight: bold;
                padding: 15px 0 0px;
                color: #555555;">{{ item.impression }}</span>
                <span style="font-weight: bold;
                font-size: 15px;
                color: #555; display: block;">IMPRESSIONS</span>
                </div>
            </div>

            <div style="flex: 1; display: flex; align-items: center; gap: 25px;">
                <span style="font-size: 24px; color: #ff5722;">
                    <img src="../../../assets//media/report_icon/playingTime.png" class="img-fluid" style="width: 40px;height: 40px;" alt="view">
                </span>
                <div style="text-align: center;">
                    <span style="font-size: 25px;
                font-weight: bold;
                padding: 15px 0 0px;
                color: #555555;">{{ item.play_time }}</span>
                <span style="font-weight: bold;
                font-size: 15px;
                color: #555; display: block;"> PLAYING TIME</span>
                </div>
            </div>

            <div style="flex: 1; display: flex; align-items: center; gap: 25px;">
                <span style="font-size: 24px; color: #ff5722;">
                    <img src="../../../assets//media/report_icon/deliveryIcn.png" class="img-fluid" style="width: 40px;height: 40px;" alt="view">
                </span>
                <div style="text-align: center;">
                    <span style="font-size: 25px;
                font-weight: bold;
                padding: 15px 0 0px;
                color: #555555;"> {{item.delivery}}%</span>
                <span style="font-weight: bold;
                font-size: 15px;
                color: #555; display: block;">DELIVERY</span>
                </div>
            </div>
        </div> -->
        <div class="row align-items-center" 
        *ngFor="let item of sharedData?.reportListing.data">
            <div class="col-lg-3 text-center mb-5">
                <div class="card">
                    <div class="thumb-image">
                        <img  [src]="item.file_path"  alt="Random Image" class="img-fluid">
                    </div>
                    <p class="mt-2 fw-bold">{{ item.title }}</p>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="d-flex gap-3 align-items-center">
                    <div class="thumb-icon">
                        <i class="far fa-eye"></i>
                    </div>
                    <div class="view-number">
                        <p>{{ item.impression }}</p>
                        <p>IMPRESSIONS</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="d-flex gap-3 align-items-center">
                    <div class="thumb-icon">
                        <i class="far fa-clock"></i>
                    </div>
                    <div class="view-number">
                        <p>{{ item.play_time }}</p>
                        <p>TOTAL PLAYING TIME</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="d-flex gap-3 align-items-center">
                    <div class="thumb-icon">
                        <i class="fas fa-truck"></i>
                    </div>
                    <div class="view-number">
                        <p>{{item.delivery}}%</p>
                        <p>DELIVERY</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</div> 
