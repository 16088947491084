import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { ActivatedRoute, Router } from '@angular/router';
import { event } from 'jquery';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-share-reports',
  templateUrl: './share-reports.component.html',
  styleUrl: './share-reports.component.scss'
})
export class ShareReportsComponent implements OnInit, OnChanges  {
  @Input() sharedData:any;
  isVisibleElement:boolean = false;
  filterLocationData:any
  currentUrl:string
  reportId:any
  ishideElement:boolean=true
  constructor(  
               private cdr:ChangeDetectorRef,private clipboard: Clipboard,private router: Router,
                private reportService:ReportsService,private activateRoute:ActivatedRoute
               ){

       
                }
  private getCompleteUrl(): string {
    return window.location.origin + this.router.url;
  }
  ngOnInit(){
    // this.currentUrl = this.getCompleteUrl();
    this.reportId = this.activateRoute.snapshot.paramMap.get('id');
   this.filterLocationData = this.sharedData?.locations
   if(this.reportId){
     this.ishideElement = false
     this.shareReportsById(this.reportId)
   }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sharedData']) {
      this.filterLocationData = this.sharedData?.locations
    }
    this.cdr.detectChanges()
  }
  printComponent(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
}
filterReports($event){
    const formData =  this.sharedData.formJson 
    formData.post_generated_locid = $event.target.value
   this.reportService.applyFilterData(formData).subscribe((res)=>{
    this.sharedData.reportListing = res;
    this.cdr.detectChanges()
  })
  
}
reportClipboard(){
  this.isVisibleElement = true;
  // this.clipboard.copy(this.currentUrl);
  this.shareReports()
  setTimeout(() => {
    this.isVisibleElement = false;
    this.cdr.detectChanges()
  }, 3000);
}

shareReports(){
  const payload =  { "reprotform": JSON.stringify(this.sharedData.reportListing)}
  this.reportService.shareReports(payload).subscribe((res)=>{
    //  this.sharedData.reportListing = res;
      this.currentUrl = window.location.origin+`/shareReports/${res.data.id}`
     this.clipboard.copy(this.currentUrl);
  })
}
shareReportsById(reportId){
  this.reportService.shareReportsById(reportId).subscribe((res)=>{
    const response  = JSON.parse(res.data.report_filter_json);
      this.sharedData = {};
      this.sharedData.reportListing = response;
      this.cdr.detectChanges()
 })
}
}
